import Compressor from 'compressorjs'

import { CompanyCode } from '@/data/company.constants'
import axiosApi from '@/plugins/axios.api'
import { trackError } from '@/utils/analytics'

// Constants for file sizes in bytes.
const ONE_MB = 1048576
const HALF_MB = ONE_MB / 2
const FIVE_MB = 5 * ONE_MB
const TEN_MB = 10 * ONE_MB

/**
 * Uploads files to the server along with metadata.
 * @param {File[]} files - An array of files to upload.
 * @param {Object} meta - Metadata object containing type, company, orderId, and user.
 * @returns {Promise<string[]>} - A promise that resolves to an array of URLs for the uploaded images.
 * @throws {string} - Throws an error if files are null or metadata is incomplete.
 */
export async function uploadPromise(files: FileList, meta: UploadMetadata) {
  if (!files) throw 'files cannot be null'
  if (!meta || !meta.type || !meta.company || !meta.orderId || !meta.user)
    throw 'incomplete metadata' + JSON.stringify(meta)

  if (typeof meta.orderId !== 'number') throw 'orderId must be a number'
  if (files.length === 0) throw 'no files to upload'

  const filesToUpload = []
  const formData = new FormData()
  const uploadDate = Date.now()
  formData.append('metaData', JSON.stringify(meta))

  for (const file of files) {
    if (file.size > HALF_MB) {
      try {
        const compressedFile = await compressImage(file)
        filesToUpload.push(compressedFile)
      } catch (err) {
        if (err instanceof Error) {
          trackError(err)
        }
        // If we can't compress, then uploading the original file is better than nothing
        filesToUpload.push(file)
      }
    } else {
      filesToUpload.push(file)
    }
  }

  filesToUpload.forEach((file, index) => {
    formData.append(`file-${index}`, file, file.name || uploadDate.toString())
  })

  const response = await axiosApi.post('/api/OrderImage/images', formData)

  const result = response.data
  const data: UploadResult[] = result.data

  if (data.some(r => !r.success)) throw result.errorMessage

  return data.map(r => `${import.meta.env.VITE_IMAGE_CDN_URL}/${r.imageName}`)
}

function compressImage(imageFile: File): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(imageFile, {
      quality: getDesiredImageQuality(imageFile.size),
      checkOrientation: false,
      maxHeight: 2000,
      maxWidth: 2000,
      success(compressedFile) {
        if (compressedFile instanceof File) {
          return resolve(compressedFile)
        }

        return resolve(
          new File([compressedFile], Date.now().toString(), { type: compressedFile.type })
        )
      },
      error(error) {
        return reject(error)
      },
    })
  })
}

function getDesiredImageQuality(fileSize: number) {
  if (fileSize > TEN_MB) {
    return 0.2
  } else if (fileSize > FIVE_MB) {
    return 0.3
  } else if (fileSize > ONE_MB) {
    return 0.7
  } else if (fileSize > HALF_MB) {
    return 0.7
  }
  return 0.7
}

/**
 * Converts a data URI to a Blob object.
 * @param {string} dataURI - The data URI to convert.
 * @returns {Blob} The Blob object representing the converted data URI.
 */
export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export interface UploadResult {
  success: boolean
  imageName: string
}

export interface UploadMetadata {
  type: string
  orderId: number
  company: CompanyCode
  user: string
}
