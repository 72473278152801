/**
 * Retrieves the saved state from local storage for the given key.
 * @param {string} key - The key to retrieve the saved state for.
 * @returns {any} - The saved state for the given key.
 */
export function getSavedState<T>(key: string): T | null {
  const item = window.localStorage.getItem(key)
  return isNotEmpty(item) ? JSON.parse(item) : null
}

/**
 * Saves the given payload to local storage under the specified key.
 * @param {string} key - The key to use for storing the payload in local storage.
 * @param {any} payload - The data to store in local storage.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any --- JSON.stringify accepts any, therefore should we
export function saveState(key: string, payload: any) {
  return window.localStorage.setItem(key, JSON.stringify(payload))
}

function isNotEmpty(value?: string | null): value is string {
  return typeof value === 'string' && value !== '' && value !== 'null'
}
