<script setup>
import { computed } from 'vue'

const props = defineProps({
  isDangerous: {
    type: Boolean,
    default: false,
  },
  notARealMaterial: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: null,
  },
})

const themeClasses = computed(() => {
  if (props.notARealMaterial) return ''
  return props.isDangerous
    ? 'border border-error bg-error/5 text-error'
    : 'border border-gray-400 dark:border-base-content'
})
</script>

<template>
  <div v-hyphen:sv class="flex items-center gap-1 rounded-2xl p-1 px-2" :class="themeClasses">
    <template v-if="count">
      {{ count }}
      <span class="font-mono text-xs opacity-60">x</span>
    </template>
    <slot />
    <BaseIcon v-if="props.isDangerous" class="h-3 text-error" name="rhombus" />
  </div>
</template>
