import { FA_BAGS, FA_CONTAINER, KARL, KRANBIL } from '@/utils/orderCategory.constants'

export function crane(o) {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === KRANBIL)
}

export function faBags(o) {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === FA_BAGS)
}

export function faContainer(o) {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === FA_CONTAINER)
}

export function faBagsNotInProd(o, IsFaRollout) {
  return faBags(o) && IsFaRollout
}

export function faContainerNotInProd(o, IsFaContainerRollout) {
  return faContainer(o) && IsFaContainerRollout
}

export function receptacle(o) {
  return Boolean(
    o.orderCategory.orderCategoryName.toLowerCase() === KARL &&
      o.receptacleQuantities &&
      o.receptacleQuantities.length > 0
  )
}

export function bagDelivery(o) {
  return Boolean(
    o.orderCategory.orderCategoryName.toLowerCase().includes('lev') &&
      o.bagDeliveryQuantities.prefix
  )
}

export function LD(o) {
  const category = o.orderCategory.orderCategoryName.toLowerCase()
  return Boolean(category === 'ld' && o.bookingActionType)
}

export function LVX(o) {
  const category = o.orderCategory.orderCategoryName.toLowerCase()
  return Boolean(category === 'lvx' && o.bookingActionType)
}

export function head(o) {
  return Boolean(!o.subOrderCode && o.subOrderCodes && o.subOrderCodes.length > 0)
}

export function sub(o) {
  return Boolean(o.subOrderCode && o.parentOrderId > 0)
}
