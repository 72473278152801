<script setup>
import { computed, onUnmounted, ref, watch } from 'vue'

import { uploadPromise } from '@/plugins/image-x'

const emit = defineEmits(['update:modelValue', 'update:loading'])

const props = defineProps({
  modelValue: {
    required: true,
    type: Array,
  },
  metadata: {
    required: true,
    type: Object,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
})

const idName = ref('image-upload')

// If we don't ensure unique ref names, we will have issues with multiple inputs with the same id
if (!window.uploadKeys) window.uploadKeys = {}

while (window.uploadKeys[idName.value]) idName.value += '1'
window.uploadKeys[idName.value] = true
onUnmounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete window.uploadKeys[idName.value]
})

const uploadRef = ref()
const queue = ref(0)

const valueWrap = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

watch(
  () => queue.value,
  newVal => {
    emit('update:loading', newVal > 0)
  }
)

async function handleUpload() {
  const files = uploadRef.value.files
  if (!files) return

  queue.value += files.length
  const res = await uploadPromise(files, props.metadata)

  if (uploadRef.value) uploadRef.value.value = ''

  queue.value -= res.length
  valueWrap.value = valueWrap.value.concat(res)
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <label class="btn btn-outline w-full gap-2" :disabled="disabled || undefined" :for="idName">
      <template v-if="queue === 0">
        {{ $t('image-upload.add') }} <BaseIcon class="h-5" name="camera" />
      </template>
      <template v-else>
        <span class="loading loading-spinner" />
        {{ $t('image-upload.uploading') }}
      </template>
    </label>
    <input
      :id="idName"
      ref="uploadRef"
      accept="image/*"
      class="hidden"
      :disabled="disabled"
      multiple
      type="file"
      @change="handleUpload"
    />

    <BaseImageGallery
      v-if="queue > 0 || valueWrap.length > 0"
      v-model="valueWrap"
      :skeletons="queue"
    />
  </div>
</template>
