import { createApp } from 'vue'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import { VueFire } from 'vuefire'
// pinia uses vuefire so this has to be before pinia!
import { createPinia } from 'pinia'

import VueCountdown from '@chenfengyuan/vue-countdown'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { registerSW } from 'virtual:pwa-register'

import loadComponents from '@/components/_register-base-components'
import hyphen from '@/directives/hyphen.ts'
import i18n from '@/i18n'
import { usePluginAnalytics } from '@/plugins/analytics'
import { firebaseApp } from '@/plugins/firebase-db' // do not move this import
import { setup as luxonSetup } from '@/plugins/luxon'
import sentry from '@/plugins/sentry.js'

import { usePluginTheme } from './plugins/theme'
import App from './App.vue'
import router from './router'

import '@/assets/fonts.css'

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DISABLED !== 'true') {
  app.use(sentry)
}

app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 24, // staleTime: 1000 * 10,
      },
    },
  },
})

app.use(i18n) // since we use translations in router, we need to init i18n before router

// both router and pinia use firebase, so this has to be before them
app.use(VueFire, {
  firebaseApp,
})

app.use(createPinia())

app.use(router) // router uses pinia, so this has to be after pinia

usePluginTheme()
usePluginAnalytics(app)

luxonSetup(app)

const updateSW = registerSW({
  onNeedRefresh() {},
})
app.use(updateSW)

loadComponents(app)

app.component(VueCountdown.name, VueCountdown)

app.use(VueGoogleMaps, { load: { key: import.meta.env.VITE_GOOGLE_MAPS_KEY } })

app.directive('hyphen', hyphen)

app.mount('#app')
