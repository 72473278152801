<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    required: false,
    type: [String, Number, Object, Boolean],
    default: null,
  },
  options: {
    required: true,
    type: Array,
  },
  keyProp: {
    default: undefined,
    type: String,
  },
  labelProp: {
    default: undefined,
    type: String,
  },
  valueProp: {
    default: undefined,
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  size: {
    default: 'md',
    type: String,
  },
})

const valueWrap = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <button
      v-for="(option, i) in options"
      :key="keyProp ? option[keyProp] : i"
      class="flex items-center rounded-lg border hover:border-gray-400 hover:bg-gray-500/10 dark:border-secondary/50 dark:hover:border-secondary"
      :class="{
        '!border-info !bg-info/20': valueProp
          ? valueWrap === option[valueProp]
          : valueWrap === option,
        'gap-2 p-2': props.size === 'sm',
        'gap-4 p-4': props.size === 'md',
      }"
      :disabled="disabled || (valueProp ? valueWrap === option[valueProp] : valueWrap === option)"
      @click="valueWrap = valueProp ? option[valueProp] : option"
    >
      <input
        :checked="valueProp ? valueWrap === option[valueProp] : valueWrap === option"
        class="radio"
        :class="{
          disabled: disabled,
          'radio-sm': props.size === 'sm',
          'radio-md': props.size === 'md',
        }"
        type="radio"
      />
      <slot :label="labelProp ? option[labelProp] : option">
        <span class="block truncate">{{ labelProp ? option[labelProp] : option }}</span>
      </slot>
    </button>
  </div>
</template>
