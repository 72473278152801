import { App } from 'vue'

import router from '@/router/index.js'
import { analyticsAreEnabled, trackError, trackPageView } from '@/utils/analytics'

export function usePluginAnalytics(app: App) {
  if (!analyticsAreEnabled()) {
    return
  }

  const prevErrorHandler = app.config.errorHandler
  app.config.errorHandler = (err, instance, info) => {
    if (err instanceof Error) {
      trackError(err)
    }
    // ensure to not override any other error handler:
    prevErrorHandler?.(err, instance, info)
    throw err
  }

  router.beforeEach((to, from, next) => {
    trackPageView(to)
    next()
  })

  router.onError(err => {
    trackError(err)
  })
}
