<script>
import { library as fontAwesomeIconLibrary } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignCenter,
  faAnglesUpDown,
  faArrowCircleRight,
  faArrowsAlt,
  faBars,
  faBoxCheck,
  faBuilding,
  faBullseye,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faCircle,
  faCircleInfo,
  faClock,
  faComment,
  faCommentAlt,
  faComments,
  faCopy,
  faCubes,
  faDesktop,
  faDirections,
  faDumpster,
  faEllipsisVertical,
  faEnvelope,
  faEraser,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faFire,
  faGripLines,
  faHexagonExclamation,
  faHome,
  faHourglassClock,
  faIdBadge,
  faImages,
  faIndustry,
  faInfoCircle,
  faLeft,
  faLevelDown,
  faLevelUp,
  faList,
  faLocationCrosshairs,
  faMapMarkedAlt,
  faMobileAlt,
  faNote,
  faPaperPlaneTop,
  faPen,
  faQuestionCircle,
  faReceipt,
  faReply,
  faRhombus,
  faRight,
  faScrewdriverWrench,
  faServer,
  faShieldExclamation,
  faSignature,
  faSquare,
  faSync,
  faTruck,
  faUser,
  faUserCircle,
  faWeightHanging,
  faXmark,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import camelCase from 'lodash/camelCase'

// https://fontawesome.com/search?q=info&o=r&s=light&f=classic
fontAwesomeIconLibrary.add(
  faExternalLink,
  faXmark,
  faCamera,
  faAnglesUpDown,
  faCheck,
  faCircleInfo,
  faRhombus,
  faHexagonExclamation,
  faQuestionCircle,
  faChevronDown,
  faReceipt,
  faEnvelope,
  faClock,
  faEraser,
  faEllipsisVertical,
  faNote,
  faShieldExclamation,
  faCopy,
  faChevronRight,
  faHourglassClock,
  faBoxCheck,
  faSignature,
  faBars,
  faLocationCrosshairs,
  faLevelDown,
  faLevelUp,
  faDesktop,
  faServer,
  faFire,
  faScrewdriverWrench,
  faPaperPlaneTop,
  // below are old ones TODO: review if they are still used
  faSync,
  faComment,
  faComments,
  faReply,
  faArrowsAlt,
  faEye,
  faDumpster,
  faDirections,
  faUser,
  faUserCircle,
  faMapMarkedAlt,
  faExclamationCircle,
  faInfoCircle,
  faBuilding,
  faExclamationTriangle,
  faCubes,
  faAlignCenter,
  faCommentAlt,
  faCaretLeft,
  faCaretDown,
  faLeft,
  faRight,
  faHome,
  faTruck,
  faCircle,
  faCheckCircle,
  faSquare,
  faCheckSquare,
  faPen,
  faWeightHanging,
  faArrowCircleRight,
  faIdBadge,
  faGripLines,
  faMobileAlt,
  faBullseye,
  faImages,
  faIndustry,
  faList
)

export default {
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: 'font-awesome',
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Gets a CSS module class, e.g. iconCustomLogo
    customIconClass() {
      return this.$style[camelCase('icon-custom-' + this.name)]
    },
  },
}
</script>

<template>
  <FontAwesomeIcon
    v-if="source === 'font-awesome'"
    v-bind="$attrs"
    aria-hidden="true"
    :icon="`fa-light fa-${name}`"
  />
  <span
    v-else-if="source === 'custom'"
    v-bind="$attrs"
    aria-hidden="true"
    :class="customIconClass"
  />
</template>
