type EnvName = 'production' | 'test' | 'local'
type FirebaseEnvName = Exclude<EnvName, 'local'>

const API_URL_TO_ENV: Record<string, EnvName> = {
  'https://rx-gateway.sortera.org': 'production',
  'https://rx-gateway-test.sortera.org': 'test',
  'http://localhost:5010': 'local',
  localhost: 'local',
}

const FIREBASE_PROJECT_TO_ENV: Record<string, FirebaseEnvName> = {
  'facilityx-dfb4d': 'production',
  'facilityx-test': 'test',
}

export function getBackendEnvName(): EnvName {
  const result =
    Object.entries(API_URL_TO_ENV).find(([part]) => import.meta.env.VITE_API_URL === part)?.[1] ??
    null
  if (result == null) {
    throw new Error(`Invalid API URL: ${import.meta.env.VITE_API_URL}`)
  }
  return result
}

export function getFirebaseEnvName(): FirebaseEnvName {
  const result =
    Object.entries(FIREBASE_PROJECT_TO_ENV).find(
      ([part]) => import.meta.env.VITE_FIREBASE_PROJECT_ID === part
    )?.[1] ?? null
  if (result == null) {
    throw new Error(`Invalid Firebase project ID: ${import.meta.env.VITE_FIREBASE_PROJECT_ID}`)
  }
  return result
}

export function isLocal(): boolean {
  return import.meta.env.VITE_ENVIRONMENT_NAME === 'local'
}

export function isDev(): boolean {
  // So we can easily exclude production-only code in development
  return (
    import.meta.env.VITE_ENVIRONMENT_NAME === 'local' ||
    import.meta.env.VITE_ENVIRONMENT_NAME === 'test'
  )
}

export function isProduction(): boolean {
  return import.meta.env.VITE_ENVIRONMENT_NAME === 'production'
}
